<template>
  <div class="login">
    <div class="login-container">
      <img src="../assets/Logo.svg" class="w-32 mx-auto mb-10" />
      <form @submit.prevent="login">
        <text-field
          dark
          type="email"
          v-model="v$.form.email.$model"
          :validation="v$.form.email"
          label="E-Mail"
        ></text-field>
        <text-field
          dark
          v-model="v$.form.password.$model"
          :validation="v$.form.password"
          label="Passwort"
          type="password"
        ></text-field>
        <action-button
          color="default"
          label="Login"
          type="submit"
          :disabled="v$.form.$invalid"
          :loading="loading"
          class="mt-3"
        />
      </form>
    </div>
  </div>
</template>

<script>
import TextField from "../components/form/TextField";
import ActionButton from "../components/form/ActionButton";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import AuthService from "../services/authService";
import { useToast, POSITION } from "vue-toastification";
import { mapMutations } from "vuex";

export default {
  name: "Login",

  setup() {
    return {
      v$: useVuelidate(),
      toast$: useToast(),
      authService: new AuthService(),
    };
  },

  components: { ActionButton, TextField },

  data: () => ({
    loading: false,
    form: {
      email: null,
      password: null,
    },
  }),

  validations: () => ({
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        min: minLength(8),
      },
    },
  }),

  methods: {
    ...mapMutations("auth", ["setUser"]),

    /**
     * Login user.
     *
     * @returns {Promise<void>}
     */
    async login() {
      this.loading = true;

      try {
        await this.authService.login(this.form.email, this.form.password);
        this.loading = false;
      } catch (error) {
        this.toast$.error(error.message, {
          position: POSITION.BOTTOM_CENTER,
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  @apply bg-primary-500;
  @apply w-screen;
  @apply h-screen;
  @apply absolute;
  @apply top-0;
  @apply left-0;

  .login-container {
    @apply w-11/12;
    max-width: 768px;
    @apply px-3;
    @apply absolute;
    @apply left-1/2;
    @apply top-1/2;
    @apply transform;
    @apply -translate-x-1/2;
    @apply -translate-y-1/2;
  }
}
</style>
